import { ReactSVG } from "react-svg";
import { NotFoundContainer } from "./styles";
import notFound from "../../assets/404.svg";
import { useNavigate } from "react-router-dom";

export const NotFoundComponente = ({ texto }) => {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <ReactSVG className="notFound" src={notFound} />
      <h3>{texto} não encontrada 😢</h3>
      <span>
        A página que você tentou acessar está indisponível ou não existe.
      </span>
      <div className="flex">
        <button onClick={() => window.history.back()}>
          Retornar á página anterior
        </button>
        <button onClick={() => navigate("/campanhas")}>
          Ir para a página inicial
        </button>
      </div>
    </NotFoundContainer>
  );
};
