import styled from "styled-components";

export const ContainerStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 191, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .div-branca{
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: var(--base);
        padding: 80px 80px 40px;
        width: 80%;
        max-width: 1000px;
        justify-content: flex-start;
        margin: 0 auto;
        text-align: start;
    }

    h2{
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    h3{
        color: rgba(116, 119, 120, 1);
    }
    
    bold{
        font-weight: 600;
        margin: 0px 5px;
    }

    button{
        width: 100%;
        max-width: 400px;
        margin: 0px auto 10px;
        border-radius: 20px;
        background-color: rgba(100, 1, 1, 1);
        padding: 10px 0px;
        border: none;
        color: white;
        font-weight: 600;
    }

    span{
        color: rgb(136, 133, 127);
        position: absolute;
        left: 30px;
        top: 30px;
        font-size: 16px;
        cursor: pointer;
        display: flex;

        bold{
            font-size: 30px;
            font-weight: 400;
        }
    }

    input{
        padding: 10px 20px;
        border: rgba(255, 191, 0, 0.7) 2px solid;
        border-radius: 8px;
        width: 100%;
        margin: 30px 0px 40px;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 40px;
        border: 2px solid yellow;
        appearance: none;
        background-color: white;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background-color: white;
        border-color: yellow;
    }

    input[type="checkbox"]:checked::after {
    content: "✔";
        color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: bold;
    }

    .flex{
        justify-content: center;
        align-items: center;
    }

    .h3-input{
        margin-left: 10px;
        margin-bottom: 5px;
    }
`