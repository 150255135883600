import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório (E-mail)."),
  senha: yup.string().required("Campo obrigatório."),
});

export const NovaSenhaSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório (E-mail)."),
});

export const EmailDoisFatores = yup.object().shape({
  email: yup
    .string()
    .email("Formato de e-mail inválido.")
    .required("Campo obrigatório (E-mail).")
})

export const resetPasswordSchema = yup.object().shape({
  novaSenha: yup
    .string()
    .required('Campo obrigatório')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número.')
    .matches(/[\W_]/, 'A senha deve conter pelo menos um caractere especial.')
    .min(8, 'A senha deve ter no mínimo 8 caracteres.'),

  confirmarSenha: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('novaSenha')], 'As senhas não coincidem'),
});
