import axios from "axios";

const apiChamadoProjetos = axios.create({
    baseURL: 'https://servicosapi.ifollow.com.br/',
    // baseURL: "http://localhost:8085",
});

apiChamadoProjetos.interceptors.request.use((config) => {
    const token = localStorage.getItem("@erp:token");
    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return config;
});

export default apiChamadoProjetos;