import { Form, InputContainer, LoginButton, VoltarButton } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../../services/api";
import LoginEmailSvg from "../../../assets/email.svg";
import { ReactSVG } from "react-svg";
import LogoVesta from "../../../assets/logo-vesta.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPasswordSchema } from "../../../schemas/login";
import { useEffect, useState } from "react";

const RefinirSenhaForm = () => {
  const [isResetting, setIsResetting] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm(
    {
      resolver: yupResolver(resetPasswordSchema),
    }
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      setIsResetting(true);
    }
  }, [token]);

  const handleFormChangePassword = (data) => {
    setLoading(true);
    api.patch(`/usuarios/senha/alterar`, {
      token: token,
      novaSenha: data.novaSenha,
    })
      .then((response) => {
        toast.success('Senha alterada com sucesso!');
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      }).finally(() => {
      });
  };

  const voltarPagina = () => {
    navigate('/')
  };

  return (
    <Form onSubmit={handleSubmit(handleFormChangePassword)}>
      <img src={LogoVesta} alt="Logo Vesta" className="logo" />
      <InputContainer>
        <label htmlFor="novaSenha">
          <ReactSVG src={LoginEmailSvg} />
        </label>
        <input
          id="novaSenha"
          placeholder="Senha"
          type="text"
          {...register("novaSenha")}
        />
        {errors.novaSenha && (
          <span className="input-error">{errors.novaSenha.message}</span>
        )}
      </InputContainer>
      <InputContainer>
        <label htmlFor="confirmarSenha">
          <ReactSVG src={LoginEmailSvg} />
        </label>
        <input
          id="confirmarSenha"
          placeholder="Confirmar senha"
          type="text"
          {...register("confirmarSenha")}
        />
        {errors.confirmarSenha && (
          <span className="input-error">{errors.confirmarSenha.message}</span>
        )}
      </InputContainer>

      <LoginButton type="submit">Enviar</LoginButton>
      <VoltarButton type="button" onClick={voltarPagina}>
        Voltar
      </VoltarButton>
    </Form>
  );
};

export default RefinirSenhaForm;
