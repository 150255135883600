import { useNavigate, useParams } from "react-router-dom";
import Menu from "../../../components/Menu";
import Briefing from "../../../assets/briefing.svg";
import Vitrine from "../../../assets/vitrine.svg";
import Material from "../../../assets/material.svg";
import { ModelCampanhaContainer } from "./style";
import { ReactSVG } from "react-svg";
import Botao from "../../../assets/voltar.svg";
import { MateriaisViaBriefing } from "../../../components/MateriaisViaBriefing";
import { MateriaisViaVitrine } from "../../../components/MaterialViaVitrine";
import { useEffect, useState } from "react";
import { EditarDataCampanha } from "../../../components/Modal/EditarDataCampanha";
import { useAuth } from "../../../providers/Auth";
import { DeletarMaterialBriefing } from "../../../components/Modal/DeletarMaterialBriefing";
import api from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import PropostaCampanha from "../../../components/PropostaCampanha";
import Loading from "../../../components/Loading";
import PedidoComprasCampanha from "../../../components/PedidoComprasCampanha";
import { HistoricoCampanha } from "../../../components/HistoricoCampanha";
import { ModalAdicionarProdutor } from "../../../components/Modal/AddProdutor";
import { NotFoundComponente } from "../../../components/NotFound";

export const MoldeDeCampanha = () => {
  const { user } = useAuth();
  const { item_id, id } = useParams();

  const navigate = useNavigate();
  const [campanha, setCampanha] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [abrirModalEditarData, setAbrirModalEditarData] = useState(false);
  const [idMaterialBriefing, setIdMaterialBriefing] = useState(0);
  const [materialBriefing, setMaterialBriefing] = useState([]);
  const [materiaisVitrine, setMateriaisVitrine] = useState([]);
  const [propostas, setPropostas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [perfil, setPerfil] = useState({});
  const [materialBriefingSelecionado, setMaterialBriefingSelecionado] =
    useState(0);

  const [
    abrirModalDeletarMaterialBriefing,
    setAbrirModalDeletarMaterialBriefing,
  ] = useState(false);

  const [campanhaLoaded, setCampanhaLoaded] = useState(false);
  const [marcasLoaded, setMarcasLoaded] = useState(false);
  const [materiaisVitrineLoaded, setMateriaisVitrineLoaded] = useState(false);
  const [materialBriefingLoaded, setMaterialBriefingLoaded] = useState(false);
  const [categoriasLoaded, setCategoriasLoaded] = useState(false);
  const [propostasLoaded, setPropostasLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itens, setItens] = useState([]);
  const [ncms, setNcms] = useState([]);

  useEffect(() => {
    if (
      campanhaLoaded &&
      marcasLoaded &&
      materiaisVitrineLoaded &&
      materialBriefingLoaded &&
      categoriasLoaded &&
      propostasLoaded
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    campanhaLoaded,
    marcasLoaded,
    materiaisVitrineLoaded,
    materialBriefingLoaded,
    categoriasLoaded,
    propostasLoaded,
  ]);

  function buscarCampanhas() {
    setCampanhaLoaded(false);
    api
      .get(`/campanhas/${id}`)
      .then((response) => {
        if (!response.data.data.campanha[0]) {
          setLoading(false);
          return setCampanha([]);
        }
        setCampanha(response.data.data.campanha[0]);
        setCampanhaLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarPerfil() {
    if (!user) {
      return;
    }

    api
      .get(`/perfis/${user.perfil_id}`)
      .then((response) => {
        setPerfil(response.data.data.perfil);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMarcas() {
    setMarcasLoaded(false);
    api
      .get(`/campanha-marca/${id}`)
      .then((response) => {
        setMarcas(response.data.data.vinculos);
        setMarcasLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMateriaisVitrine() {
    setMateriaisVitrineLoaded(false);
    api
      .get(`/campanha-material-vitrine/${id}`)
      .then((response) => {
        setMateriaisVitrine(response.data.data.materiais);
        setMateriaisVitrineLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarMaterialBriefing() {
    setMaterialBriefingLoaded(false);
    api
      .get(`/campanha-material-briefing/${id}`)
      .then((response) => {
        setMaterialBriefing(response.data.data.materiais);
        setMaterialBriefingLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaItem() {
    setCategoriasLoaded(false);
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
        setCategoriasLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  const buscarPropostas = () => {
    setPropostasLoaded(false);
    api
      .get(`/propostas/${id}`)
      .then((response) => {
        setPropostas(response.data.data.propostas);
        setPropostasLoaded(true);
      })
      .catch((error) => {
        toast.error("Erro ao buscar proposta");
      });
  };

  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const buscarNCM = () => {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcms(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  function buscarDadosCampanha() {
    buscarCampanhas();
    buscarMarcas();
    buscarMateriaisVitrine();
    buscarMaterialBriefing();
    buscarCategoriaItem();
    buscarPropostas();
    buscarItens();
    buscarNCM();
  }

  useEffect(() => {
    buscarDadosCampanha();
  }, []);

  useEffect(() => {
    buscarPerfil();
  }, [user]);

  const elementoClicado = document.getElementById(item_id);

  // Rola até o elemento
  if (elementoClicado) {
    elementoClicado.classList.add("highlight");
    setTimeout(function () {
      elementoClicado.classList.remove("highlight");
    }, 1500);
    elementoClicado.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  return (
    <ModelCampanhaContainer>
      <div className="flex">
        <Menu />
        {loading ? (
          <Loading />
        ) : !campanhaLoaded && campanha.length === 0 ? (
          <NotFoundComponente texto="Campanha" />
        ) : (
          <div className="body">
            <div className="div-top">
              <div className="div-detalhes">
                <ReactSVG
                  className="back"
                  onClick={() => navigate("/campanhas")}
                  src={Botao}
                />
                <div className="detalhes">
                  <h1># {campanha && campanha.id}</h1>
                  <h2 className="nome-campanha">{campanha && campanha.nome}</h2>
                  <div className="marcas">
                    <span className="font-weight">Marca: </span>
                    <div className="flex">
                      {marcas &&
                        marcas.map((element, index) => (
                          <p key={index}>{element.nome}</p>
                        ))}
                    </div>
                  </div>
                  <div className="flex">
                    <h3>
                      <span className="font-weight">Prazo final:</span>{" "}
                      {campanha && campanha.prazo_final}
                    </h3>
                    <span
                      onClick={() => setAbrirModalEditarData(true)}
                      className="editar"
                    >
                      editar
                    </span>
                  </div>
                </div>
              </div>
              {!user.permissoes.includes("produtor") && (
                <div className="add-campaing">
                  <div className="flex">
                    <ReactSVG src={Material} />
                    <h3>Adicionar material</h3>
                  </div>
                  <div className="flex">
                    <div
                      onClick={() => navigate(`/campanha/briefing/${id}`)}
                      className="div-right"
                    >
                      <div className="info-div">
                        <ReactSVG src={Briefing} className="icon" />
                        <h4>BRIEFING</h4>
                        <span>fazer pedido &gt;</span>
                      </div>
                      <h5>Meu material é muito mais especifico</h5>
                    </div>
                    <div>
                      <div className="div-right">
                        <div
                          onClick={() => navigate("/vitrine")}
                          className="info-div"
                        >
                          <ReactSVG src={Vitrine} className="icon" />
                          <h4>VITRINE</h4>
                          <span>ver todos &gt;</span>
                        </div>
                        <h5>Material pré-cadastrados</h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <MateriaisViaBriefing
              id={id}
              state={setIdMaterialBriefing}
              setAbrirModalDeletarMaterialBriefing={
                setAbrirModalDeletarMaterialBriefing
              }
              materialBriefing={materialBriefing}
              categorias={categorias}
              perfil={perfil}
              campanha={campanha}
              buscarCampanhas={buscarCampanhas}
              item_id={item_id}
              buscarMaterialBriefing={buscarMaterialBriefing}
            />
            <MateriaisViaVitrine
              materiaisVitrine={materiaisVitrine.filter(
                (material) => material.status === "pendente"
              )}
              buscarMateriaisVitrine={buscarMateriaisVitrine}
            />
            <PropostaCampanha
              campanha={campanha}
              materiaisBriefing={materialBriefing.filter(
                (material) => material.status !== "pendente"
              )}
              materiaisVitrine={materiaisVitrine.filter(
                (material) => material.status !== "pendente"
              )}
              propostas={propostas}
              buscarDadosCampanha={buscarDadosCampanha}
              ncms={ncms}
              itens={itens}
            />
            <PedidoComprasCampanha
              campanha={campanha}
              propostas={propostas}
              buscarMaterialBriefing={buscarMaterialBriefing}
              marcas={marcas}
            />
            <HistoricoCampanha />
          </div>
        )}
      </div>
      {abrirModalEditarData && campanha && (
        <EditarDataCampanha
          state={setAbrirModalEditarData}
          data={campanha}
          buscarCampanhas={buscarCampanhas}
        />
      )}
      {abrirModalDeletarMaterialBriefing && (
        <DeletarMaterialBriefing
          state={idMaterialBriefing}
          setAbrirModalDeletarMaterialBriefing={
            setAbrirModalDeletarMaterialBriefing
          }
          buscarMaterialBriefing={buscarMaterialBriefing}
        />
      )}
      <ToastContainer />
    </ModelCampanhaContainer>
  );
};
