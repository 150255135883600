import Menu from "../../../components/Menu";
import Botao from "../../../assets/voltar.svg";
import { BriefingStyle } from "./style";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { FormComunicacaoVisual } from "../../../components/Forms/FormComunicacaoVisualBriefing";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { FormBrinde } from "../../../components/Forms/FormBrindeBriefing";
import { FormPontoDeVenda } from "../../../components/Forms/FormPontoDeVenda";
import { FormMaterialImpresso } from "../../../components/Forms/FormMaterialImpresso";
import { useAuth } from "../../../providers/Auth";
import { FormServico } from "../../../components/Forms/FormServico";
import { NotFoundComponente } from "../../../components/NotFound";
import Loading from "../../../components/Loading";

const BriefingPage = () => {
  const navigate = useNavigate();
  const urlAtual = window.location.href;
  const urlObj = new URL(urlAtual);
  const id = urlObj.pathname.split("/").pop();
  const [itens, setItens] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(1);
  const [clienteId, setClienteId] = useState();
  const [bloqueio, setBloqueio] = useState({ loading: false, value: false });
  const { user } = useAuth();

  const { register, getValues } = useForm();

  const mudarCategoria = () => {
    const value = getValues("categoria");
    if (categoriaSelecionada != value && value != "") {
      if (Number(categoriaSelecionada) > 0) {
        setCategoriaSelecionada(categoriaSelecionada);
      }
      setCategoriaSelecionada(value);
    }
  };

  function buscarCampanhas() {
    setBloqueio({ ...bloqueio, loading: true });

    api
      .get(`/campanhas/${id}`)
      .then((response) => {
        if (!response.data.data.campanha[0]) {
          return setBloqueio({ loading: false, value: true });
        }

        setBloqueio({ loading: false, value: false });
        buscarItens();
        buscarCategoriaItem();
        buscarCliente();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarItens() {
    api
      .get(`/itens`)
      .then((response) => {
        setItens(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCliente() {
    api
      .get(`/usuario-cliente/${user.id}`)
      .then((response) => {
        setClienteId(response.data.data.usuario.cliente_id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCategoriaItem() {
    api
      .get(`/categoria-item`)
      .then((response) => {
        setCategorias(response.data.data.categorias);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  useEffect(() => {
    buscarCampanhas();
  }, []);

  return (
    <BriefingStyle>
      <Menu />
      {bloqueio.loading ? (
        <Loading />
      ) : bloqueio.value ? (
        <NotFoundComponente texto="Campanha" />
      ) : (
        <div className="div-right">
          <div className="title">
            <ReactSVG
              onClick={() => navigate(`/campanha/detalhes/${id}`)}
              src={Botao}
              className="back"
            />
            <h1>Briefing</h1>
          </div>
          <label>Tipo de Material</label>
          <select
            className="input_categoria"
            {...register("categoria")}
            onClick={() => mudarCategoria()}
          >
            <option value="">Selecionar Categoria</option>
            <option value="Material Impresso">Material Impresso</option>
            <option value="Comunicação Visual">Comunicação Visual</option>
            <option value="Materiais de Ponto de Venda">
              Materiais de Ponto de Venda
            </option>
            <option value="Brindes">Brindes</option>
            <option value="Serviço">Serviço</option>
          </select>
          {categoriaSelecionada == "Comunicação Visual" &&
            categorias.length > 0 && (
              <FormComunicacaoVisual
                itens={itens}
                categoriaSelecionada={
                  categorias.find(
                    (element) => element.nome == categoriaSelecionada
                  ).id
                }
                clienteId={clienteId}
              />
            )}
          {categoriaSelecionada == "Materiais de Ponto de Venda" &&
            categorias.length > 0 && (
              <FormPontoDeVenda
                itens={itens}
                categoriaSelecionada={
                  categorias.find((element) => element.nome == "Ponto de Venda")
                    .id
                }
                clienteId={clienteId}
              />
            )}
          {categoriaSelecionada == "Brindes" && categorias.length > 0 && (
            <FormBrinde
              itens={itens}
              categoriaSelecionada={
                categorias.find(
                  (element) => element.nome == categoriaSelecionada
                ).id
              }
              clienteId={clienteId}
            />
          )}
          {categoriaSelecionada == "Serviço" && categorias.length > 0 && (
            <FormServico
              itens={itens}
              categoriaSelecionada={
                categorias.find(
                  (element) => element.nome == categoriaSelecionada
                ).id
              }
              clienteId={clienteId}
            />
          )}
          {categoriaSelecionada == "Material Impresso" &&
            categorias.length > 0 && (
              <FormMaterialImpresso
                itens={itens}
                categoriaSelecionada={
                  categorias.find(
                    (element) => element.nome == categoriaSelecionada
                  ).id
                }
                clienteId={clienteId}
              />
            )}
        </div>
      )}
    </BriefingStyle>
  );
};

export default BriefingPage;
