
import Dashboard from '../../components/Dashboard';
import Menu from '../../components/Menu';
import { DashBoardContainer } from './styles';
import { useState } from 'react';
import { AvisoDoisFatores } from '../../components/Modal/AvisoDoisFatores';
import { CodigoDoisFatores } from '../../components/Modal/CodigoDoisFatores';
import { ConfigurarDoisFatores } from '../../components/Modal/ConfigurarDoisFatores';
import { useForm } from 'react-hook-form';
import { AbrirChamado } from '../../components/Modal/AbrirChamado';
import { useAuth } from '../../providers/Auth';

const DashboardPage = () => {
    const {
        register,
        setValue,
        getValues,
    } = useForm({
    });
    const { user } = useAuth()

    const [abnirAviso, setAbnirAviso] = useState(user.dois_fatores_chave || user.dois_fatores_chave === 0 ? false : true)
    const [configurarDoisFatores, setConfigurarDoisFatores] = useState(false)
    const [codigo, setCodigo] = useState(false)
    const [chamado, setChamado] = useState(false)

    return (
        <DashBoardContainer>
            <Menu />
            <Dashboard />
            {abnirAviso &&
                <AvisoDoisFatores
                    setModal={setAbnirAviso}
                    setCodigo={setCodigo}
                />
            }
            {/* {configurarDoisFatores &&
                <ConfigurarDoisFatores
                    register={register}
                    setModal={setConfigurarDoisFatores}
                    setCodigo={setCodigo}
                    getValues={getValues}
                    setValue={setValue}
                />
            } */}
            {codigo &&
                <CodigoDoisFatores
                    setModal={setCodigo}
                    getValues={getValues}
                    register={register}
                    setValue={setValue}
                    abrirChamado={setChamado}
                />
            }
        </DashBoardContainer>
    );
};

export default DashboardPage;