import axios from "axios";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: "https://apioutsourcing.vesta.com.br/",
  // baseURL: "http://localhost:8083",
});


api.interceptors.request.use((config) => {
  const token = localStorage.getItem("@erp:token");
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const token = localStorage.getItem("@erp:token");

    if (error.response) {

      if (error.response.status === 401 && token) {
        localStorage.removeItem("@erp:token");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
