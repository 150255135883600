import { ContainerStyle } from "./style";
import { useAuth } from "../../../providers/Auth";
import { toast } from "react-toastify";
import apiChamadoProjetos from "../../../services/apiChamadoProjetos";

export const AbrirChamado = ({ setModal, register, getValues }) => {
    const { user } = useAuth();

    function enviarChamado() {
        const body = {
            usuario_id: user.id,
            email: user.email,
            descricao: getValues("descricaoChamado"),
            projeto: "Outsourcing Vesta"
        }

        apiChamadoProjetos.post("/chamados-projetos", [body])
            .then(() => {
                toast.success("Suporte solicitado com sucesso!")
                setModal(false)
            })
            .catch(() => {
                toast.error("Algo deu errado, tente novamente mais tarde!")
            })
    }

    return (
        <ContainerStyle>
            <div className="div-branca">
                <h2>Está com problemas com seu email?</h2>
                <h3>Abra o seu chamado e já entraremos em contato com você</h3>
                <textarea {...register("descricaoChamado")} maxLength={100} />
                <span onClick={() => setModal(false)}>
                    <b>{"<"}</b> Voltar
                </span>
                <button onClick={() => enviarChamado()}>
                    Enviar
                </button>
            </div>
        </ContainerStyle>
    );
};
