import { BoxLeft, BoxRight, NovaSenhaContainer } from "./styles";
import loginVesta from "../../assets/imagem-login.svg";
import { motion } from "framer-motion";
import RefinirSenhaForm from "../../components/Forms/RedefinirSenhaForm";

const RedefinirSenha = () => {
  return (
    <NovaSenhaContainer>
      <BoxLeft>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <RefinirSenhaForm />
        </motion.div>
      </BoxLeft>
      <BoxRight>
        <img src={loginVesta} alt="gestao" />
      </BoxRight>
    </NovaSenhaContainer>
  );
};

export default RedefinirSenha;
