import { useForm } from "react-hook-form";
import { useRef, useState, useEffect } from "react";
import { ContainerStyle } from "./style";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useAuth } from "../../../providers/Auth";
import { useNavigate } from "react-router-dom";

export const CodigoDoisFatores = ({ setModal, getValues, register, setValue, abrirChamado, voltarPraLogin }) => {
    let podeMandar = true
    const inputRefs = useRef([]);
    const [contador, setContador] = useState(30);
    const [podeReenviar, setPodeReenviar] = useState(false);
    const { setToken } = useAuth();
    const navigate = useNavigate()

    useEffect(() => {
        if (contador > 0) {
            const timer = setTimeout(() => setContador(contador - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setPodeReenviar(true);
        }
    }, [contador]);

    function handleChange(e, index) {
        const value = e.target.value;
        setValue(`caracter${index}`, value);
        if (value && index < 6) {
            inputRefs.current[index].focus();
        } else if (value && index === 6) {
            enviarCodigo()
        }
    };

    function handleKeyDown(e, index) {
        if (e.key === "Backspace" && !getValues(`caracter${index}`) && index > 1) {
            inputRefs.current[index - 2].focus();
        }
    };

    function reenviarCodigo() {
        if (podeReenviar) {
            setContador(30);
            setPodeReenviar(false);
            enviarCodigoEmail()
            toast.success("Email enviado! 😉")
        }
    };

    function enviarCodigo() {
        api.post("/usuarios/dois-fatores/verificar", {
            token: ["caracter1", "caracter2", "caracter3", "caracter4", "caracter5", "caracter6"]
                .map(getValues)
                .join("")
        })
            .then((response) => {
                const token = response.headers["x-access-token"];
                localStorage.setItem("@erp:token", token);
                setToken(token);
                navigate("/dashboard")
            }).catch((error) => {
                toast.error("Código Inválido! 😢")
            })
    }

    function enviarCodigoEmail() {
        api.post("/usuarios/dois-fatores/email")
            .then((response) => {
            })
            .catch(() => {
                toast.error("Erro ao enviar código de autenticação. Tente novamente.");
            });
    }

    useEffect(() => {
        if (podeMandar) {
            enviarCodigoEmail()
            podeMandar = false
        }
    }, [])

    return (
        <ContainerStyle>
            <div className="div-branca">
                <h2>Valide o seu Código de Autenticação</h2>
                <h3>Código enviado para o e-mail {getValues("email")}</h3>
                <div className="flex">
                    {[1, 2, 3, 4, 5, 6].map((i) => (
                        <input
                            key={i}
                            type="text"
                            {...register(`caracter${i}`)}
                            maxLength="1"
                            ref={(el) => (inputRefs.current[i - 1] = el)}
                            onChange={(e) => handleChange(e, i)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                        />
                    ))}
                </div>
                <h4>
                    Ainda não recebeu seu código?
                    {podeReenviar ? (
                        <bold className="vermelho" onClick={() => reenviarCodigo()}>Reenviar código</bold>
                    ) : (
                        <bold>Reenvie após {contador} segundos</bold>
                    )}
                </h4>
                <h5>Perdeu o acesso a este e-mail?</h5>
                <button onClick={() => abrirChamado(true)}>Entre em contato com o suporte</button>
                <span onClick={() => {
                    localStorage.removeItem("@erp:token");
                    window.location.reload()
                }
                }>
                    <b>{"<"}</b> Voltar
                </span>
            </div>
        </ContainerStyle >
    );
};
