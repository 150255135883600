import styled from "styled-components";

export const ContainerStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 191, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .div-branca{
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: var(--base);
        padding: 80px 80px 40px;
        width: 80%;
        max-width: 1000px;
        justify-content: flex-start;
        margin: 0 auto;
        text-align: start;
    }

    h2{
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    h3{
        color: rgba(116, 119, 120, 1);
    }
    
    bold{
        font-weight: 600;
        margin: 0px 5px;
    }

    button{
        width: max-content;
        margin: 0px auto 10px;
        border-radius: 30px;
        background-color: rgba(100, 1, 1, 1);
        padding: 6px 28px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 14px;
    }

    span{
        color: rgb(136, 133, 127);
        position: absolute;
        left: 30px;
        top: 30px;
        font-size: 16px;
        cursor: pointer;
        display: flex;

        bold{
            font-size: 30px;
            font-weight: 400;
        }
    }

    input{
        padding: 10px 16px;
        width: 50px;
        border: rgba(255, 191, 0, 0.7) 2px solid;
        border-radius: 8px;
        margin: 50px 10px 0px;
    }

    .flex{
        justify-content: center;
        align-items: center;
    }

    .h3-input{
        margin-left: 10px;
        margin-bottom: 5px;
    }

    h4{
        margin: 20px auto 20px;

        bold{
            cursor: pointer;
            text-decoration: underline;
        }

        .vermelho{
            color: rgba(100, 1, 1, 1);
        }
    }

    h5{
        margin: 30px auto 5px;
        color: rgba(100, 1, 1, 1);
    }
`