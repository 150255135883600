import styled from "styled-components";

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .logo {
    margin-top: 20px;
  }

  .notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    div {
      width: 45%;
      margin-top: 20px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
  }

  span {
    margin: 10px 0px;
  }

  .flex {
    bottom: 80px;
  }

  button {
    border: none;
    margin-right: 20px;
    background-color: var(--principal);
    padding: 8px 20px;
    color: white;
    border-radius: 100px;
    font-weight: 600;
  }

  button + button {
    border: 1px solid var(--principal);
    background-color: white;
    color: var(--principal);
  }
`;
