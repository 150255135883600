
import { useForm } from 'react-hook-form';
import { CodigoDoisFatores } from '../../components/Modal/CodigoDoisFatores';
import { NovaSenhaPageContainer } from './styles';
import { useState } from 'react';
import { AbrirChamado } from '../../components/Modal/AbrirChamado';

const TelaCodigo = () => {
    const { register, setValue, getValues } = useForm()
    const [codigo, setCodigo] = useState()
    const [chamado, setChamado] = useState(false)

    return (
        <NovaSenhaPageContainer>
            <CodigoDoisFatores
                setModal={setCodigo}
                getValues={getValues}
                register={register}
                setValue={setValue}
                abrirChamado={setChamado}
            />
            {
                chamado &&
                <AbrirChamado setModal={setChamado} register={register} getValues={getValues} setValue={setValue} />
            }
        </NovaSenhaPageContainer>
    );
};

export default TelaCodigo;