import { Form, InputContainer, LoginButton, VoltarButton } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../../services/api";
import LoginEmailSvg from "../../../assets/email.svg";
import { ReactSVG } from "react-svg";
import LogoVesta from "../../../assets/logo-vesta.svg";
import { useNavigate } from "react-router-dom";
import { NovaSenhaSchema } from "../../../schemas/login";
import { useState } from "react";

const NovaSenhaForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NovaSenhaSchema),
  });

  function onSubmitFunction(data) {
    setLoading(true);
    api.post(`/usuarios/senha?email=${encodeURIComponent(data.email)}`)
      .then((response) => {
        toast.success('Se este e-mail estiver registrado, você receberá um link de recuperação de senha');
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      }).finally(() => {
        setLoading(false);
      });
  }

  function navigateToLogin() {
    navigate("/");
  }

  return (
    <Form onSubmit={handleSubmit(onSubmitFunction)}>
      <img src={LogoVesta} alt="Logo Vesta" className="logo" />
      <p>
        Informe seu e-mail e enviaremos uma senha provisória para o e-mail
        cadastrado.
      </p>
      <InputContainer>
        <label htmlFor="email">
          <ReactSVG src={LoginEmailSvg} />
        </label>
        <input
          id="email"
          placeholder="E-mail"
          type="text"
          autoComplete="username"
          {...register("email")}
        />
        {errors.email && (
          <span className="input-error">{errors.email.message}</span>
        )}
      </InputContainer>

      <LoginButton type="submit">Enviar</LoginButton>
      <VoltarButton type="button" onClick={navigateToLogin}>
        Voltar
      </VoltarButton>
    </Form>
  );
};

export default NovaSenhaForm;
