import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import { useAuth } from "../providers/Auth";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import NovaSenhaPage from "../pages/NovaSenhaPage";
import DashboardPage from "../pages/DashboardPage";
import CadastroDeUsuarioPage from "../pages/Usuario/CadastroDeUsuarioPage";
import NovoUsuarioPage from "../pages/Usuario/NovoUsuarioPage";
import EditarUsuarioPage from "../pages/Usuario/EditarUsuarioPage";
import ClientesPage from "../pages/ClientesPage";
import NovoClientePage from "../pages/ClientesPage/NovoClientePage";
import EditarClientePage from "../pages/ClientesPage/EditarClientePage";
import EditarCategoriaPage from "../pages/CategoriaFornecedorPage/EditarCategoriaPage";
import CategoriaFornecedorPage from "../pages/CategoriaFornecedorPage";
import FornecedoresPage from "../pages/FornecedorPage";
import NovoFornecedorPage from "../pages/FornecedorPage/NovoFornecedorPage";
import EditarFornecedorPage from "../pages/FornecedorPage/EditarFornecedorPage";
import PermissoesPage from "../pages/PermissoesPage";
import NovaPermissaoPage from "../pages/PermissoesPage/NovaPermissaoPage";
import EditarPermissaoPage from "../pages/PermissoesPage/EditarPermissaoPage";
import PerfisPage from "../pages/Perfis";
import NovoPerfilPage from "../pages/Perfis/NovoPerfilPage";
import EditarPerfilPage from "../pages/Perfis/EditarPerfilPage";
import CadastrarNovaCategoriaPage from "../pages/CategoriaFornecedorPage/CadastrarNovaCategoriaPage";
import ItensPage from "../pages/ItensPage";
import ItensCategoriaPage from "../pages/ItensCategoriaPage";
import NovaCategoriaItemPage from "../pages/ItensCategoriaPage/NovaCategoriaItemPage";
import EditarCategoriaItemPage from "../pages/ItensCategoriaPage/EditarCategoriaItemPage";
import NovoItemPage from "../pages/ItensPage/NovoItemPage";
import EditarItemPage from "../pages/ItensPage/EditarItemPage";
import CampanhaPage from "../pages/CampanhasPage";
import { CriarCamapanha } from "../pages/CampanhasPage/CriarCampanhaPage";
import { MoldeDeCampanha } from "../pages/CampanhasPage/MoldeDeCampanha";
import BriefingPage from "../pages/CampanhasPage/BriefingPage";
import { VitrinePage } from "../pages/VitrinePage";
import { VerTodosVitrinePage } from "../pages/VerTodosVitrine";
import { ProdutoVitrinePage } from "../pages/VitrinePage/ProdutoVitrinePage";
import { DisputaPage } from "../pages/Disputa";
import { FornecedorCampanhaPage } from "../pages/FornecedorCampanhaPage";
import { PropostaFornecedorPage } from "../pages/PropostaFornecedorPage";
import MarcaPage from "../pages/MarcaPage";
import NovaMarcaPage from "../pages/MarcaPage/NovaMarcaPage";
import EditarMarcaPage from "../pages/MarcaPage/EditarMarcaPage";
import NcmPage from "../pages/NcmPage";
import NovoNcmPage from "../pages/NcmPage/NovoNcmPage";
import EditarNcmPage from "../pages/NcmPage/EditarNcmPage";
import ImpostoPage from "../pages/ImpostoPage";
import NovoImposto from "../components/Cadastros/Novo/NovoImposto";
import NovoImpostoPage from "../pages/ImpostoPage/NovoItemPage";
import EditarImpostoPage from "../pages/ImpostoPage/EditarItemPage";
import ConcorrenciaPage from "../pages/ConcorrenciaPage";
import BriefingPageEditar from "../components/Cadastros/Editar/EditarBriefing";
import { AdministrarItemVritrinePage } from "../pages/AdministrarItemVitrinePage";
import { ValorMaximoProposta } from "../pages/ValorMaximoProposta";
import ItemVitrinePage from "../pages/ItemVitrine";
import { NovoItemVitrinePage } from "../pages/ItemVitrinePage/NovoItemVitrinePage";
import { EditarItemVitrinePage } from "../pages/ItemVitrinePage/EditarItemVitrinePage";
import TelaCodigo from "../pages/TelaCodigo";
import RefinirSenhaForm from "../components/Forms/RedefinirSenhaForm";
import RedefinirSenhaPage from "../pages/RedefirnirSenhaPage";

const Router = () => {
  const { user, userLoaded } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            userLoaded && (user ? <Navigate to="/dashboard" /> : <LoginPage />)
          }
        />

        <Route
          path="/codigo"
          element={<CustomRoute element={TelaCodigo} />}
        />

        {/* <Route path="/login" element={<LoginPage />} /> */}

        <Route path="/senha" element={<NovaSenhaPage />} />

        <Route path="/redefinir/senha/" element={<RedefinirSenhaPage />} />

        <Route
          path="/dashboard"
          element={<CustomRoute element={DashboardPage} />}
        />

        <Route
          path="/usuarios"
          element={
            <CustomRoute
              element={CadastroDeUsuarioPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/usuario/novo"
          element={
            <CustomRoute element={NovoUsuarioPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/usuario/editar/:id"
          element={
            <CustomRoute element={EditarUsuarioPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/permissoes"
          element={
            <CustomRoute element={PermissoesPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/permissao/nova"
          element={
            <CustomRoute element={NovaPermissaoPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/permissao/editar/:id"
          element={
            <CustomRoute
              element={EditarPermissaoPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/perfis"
          element={<CustomRoute element={PerfisPage} permissions={["admin"]} />}
        />

        <Route
          path="/Perfil/novo"
          element={
            <CustomRoute element={NovoPerfilPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/perfil/editar/:id"
          element={
            <CustomRoute element={EditarPerfilPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/fornecedor/categoria"
          element={
            <CustomRoute
              element={CategoriaFornecedorPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/fornecedores"
          element={
            <CustomRoute element={FornecedoresPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/Fornecedor/novo"
          element={
            <CustomRoute element={NovoFornecedorPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/fornecedor/editar/:id"
          element={
            <CustomRoute
              element={EditarFornecedorPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/categoria/nova"
          element={
            <CustomRoute
              element={CadastrarNovaCategoriaPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/categoria/editar/:id"
          element={
            <CustomRoute
              element={EditarCategoriaPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/clientes"
          element={
            <CustomRoute element={ClientesPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/cliente/novo"
          element={
            <CustomRoute element={NovoClientePage} permissions={["admin"]} />
          }
        />

        <Route
          path="/cliente/editar/:id"
          element={
            <CustomRoute element={EditarClientePage} permissions={["admin"]} />
          }
        />

        <Route
          path="/itens"
          element={<CustomRoute element={ItensPage} permissions={["admin"]} />}
        />

        <Route
          path="/itemVitrine"
          element={
            <CustomRoute element={ItemVitrinePage} permissions={["admin"]} />
          }
        />

        <Route
          path="/itemVitrine/novo"
          element={
            <CustomRoute
              element={NovoItemVitrinePage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/itemVitrine/editar/:id"
          element={
            <CustomRoute
              element={EditarItemVitrinePage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/item/novo"
          element={
            <CustomRoute element={NovoItemPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/item/editar/:id"
          element={
            <CustomRoute element={EditarItemPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/itens/categoria"
          element={
            <CustomRoute element={ItensCategoriaPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/item/categoria/nova"
          element={
            <CustomRoute
              element={NovaCategoriaItemPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/item/categoria/editar/:id"
          element={
            <CustomRoute
              element={EditarCategoriaItemPage}
              permissions={["admin"]}
            />
          }
        />

        <Route
          path="/campanhas"
          element={
            <CustomRoute element={CampanhaPage} permissions={["cliente"]} />
          }
        />

        <Route
          path="/campanha/nova"
          element={
            <CustomRoute element={CriarCamapanha} permissions={["cliente"]} />
          }
        />

        <Route
          path="/campanha/detalhes/:id"
          element={
            <CustomRoute element={MoldeDeCampanha} permissions={["cliente"]} />
          }
        />

        <Route
          path="/campanha/detalhes/:id/:item_id"
          element={
            <CustomRoute element={MoldeDeCampanha} permissions={["cliente"]} />
          }
        />

        <Route
          path="/campanha/briefing/:id"
          element={
            <CustomRoute element={BriefingPage} permissions={["cliente"]} />
          }
        />

        <Route
          path="/vitrine"
          element={
            <CustomRoute element={VitrinePage} permissions={["cliente"]} />
          }
        />

        <Route
          path="/vitrine/categoria/:categoria"
          element={
            <CustomRoute
              element={VerTodosVitrinePage}
              permissions={["cliente"]}
            />
          }
        />

        <Route
          path="/vitrine/:material_id"
          element={
            <CustomRoute
              element={ProdutoVitrinePage}
              permissions={["cliente"]}
            />
          }
        />

        <Route
          path="/vitrine/:material_id/editar"
          element={
            <CustomRoute
              element={AdministrarItemVritrinePage}
              permissions={["compras"]}
            />
          }
        />

        <Route
          path="/disputa/:id"
          element={
            <CustomRoute element={DisputaPage} permissions={["disputa"]} />
          }
        />

        <Route
          path="/fornecedor/campanhas"
          element={
            <CustomRoute
              element={FornecedorCampanhaPage}
              permissions={["fornecedor"]}
            />
          }
        />

        <Route
          path="/fornecedor/campanha/:id"
          element={
            <CustomRoute
              element={PropostaFornecedorPage}
              permissions={["fornecedor"]}
            />
          }
        />

        <Route
          path="/marcas"
          element={<CustomRoute element={MarcaPage} permissions={["admin"]} />}
        />

        <Route
          path="/marca/nova"
          element={
            <CustomRoute element={NovaMarcaPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/marca/editar/:id"
          element={
            <CustomRoute element={EditarMarcaPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/ncms"
          element={<CustomRoute element={NcmPage} permissions={["admin"]} />}
        />

        <Route
          path="/ncm/novo"
          element={
            <CustomRoute element={NovoNcmPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/ncm/editar/:id"
          element={
            <CustomRoute element={EditarNcmPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/imposto"
          element={
            <CustomRoute element={ImpostoPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/imposto/novo"
          element={
            <CustomRoute element={NovoImpostoPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/imposto/editar/:id"
          element={
            <CustomRoute element={EditarImpostoPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/concorrencia"
          element={
            <CustomRoute element={ConcorrenciaPage} permissions={["admin"]} />
          }
        />

        <Route
          path="/campanha/detalhes/:id/editar/:categoria"
          element={
            <CustomRoute
              element={BriefingPageEditar}
              permissions={["cliente"]}
            />
          }
        />

        <Route
          path="/valorMaximo"
          element={
            <CustomRoute
              element={ValorMaximoProposta}
              permissions={["compras"]}
            />
          }
        />

        {/* <Route path="/home" element={<CustomRoute element={NotFoundPage} />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
