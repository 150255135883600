import { ReactSVG } from "react-svg";
import { FormAdministrarItemStyle, FormStyle, TableContainer } from "./style";
import voltar from "../../../assets/voltar.svg";
import { useNavigate } from "react-router-dom";
import { Input } from "../../Input";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ModalImagemAdministrarVitrine } from "../../Modal/ImagemAdministrarVitrine";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { FaixaDePreco } from "../../FaixaDePreco";
import Loading from "../../Loading";

export const FormAdministrarItem = ({
  materialVitrine,
  materialVitrineValores,
  materialVitrineImagens,
  material_id,
  buscarMaterialVitrine,
  buscarMaterialVitrineImagens,
  buscarMaterialVitrineValores,
  categorias,
}) => {
  const navigate = useNavigate();
  const [fornecedor, setFornecedor] = useState();
  const [fotosSelecionadas, setFotosSelecionadas] = useState([]);
  const [imagemIndex, setImagemIndex] = useState();
  const [abrirModalImagem, setAbrirModalImagem] = useState(false);
  const [ncms, setNcms] = useState();
  const { register, getValues, reset, handleSubmit } = useForm();

  function buscarFornecedores() {
    api
      .get("/fornecedores")
      .then((response) => {
        const fornecedores = response.data.data.fornecedores;
        setFornecedor(
          fornecedores.find(
            (elem) => elem.id == materialVitrineValores[0]?.fornecedor_id
          )
        );
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const buscarNCM = () => {
    api
      .get(`/ncm`)
      .then((response) => {
        setNcms(response.data.data.ncms);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  };

  useEffect(() => {
    const objeto = {
      ...materialVitrine,
    };
    materialVitrine.status = materialVitrine.status === 1 ? "on" : "";
    materialVitrine.vizualizar_vitrine =
      materialVitrine.vizualizar_vitrine === 1 ? "on" : "";
    reset(objeto);
  }, [ncms]);

  const adicionarImagem = () => {
    const formData = new FormData();

    fotosSelecionadas.forEach((foto, index) => {
      formData.append(`fotos[${index}]`, foto);
    });

    api
      .post(`/material-vitrine/${material_id}/anexos`, formData)
      .then((response) => {
        toast.success("Imagem Adicionada");
        buscarMaterialVitrine();
        buscarMaterialVitrineImagens();
        buscarMaterialVitrineValores();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const atualizarMaterialVitrine = () => {
    const nome = getValues("nome");
    const observacao = getValues("observacao");
    const categoria = getValues("categoria");
    const status = getValues("status") == true ? 1 : 0;
    const vizualizarVitrine = getValues("vizualizar_vitrine") == true ? 1 : 0;
    const ncm_id = getValues("ncm_id");
    const objeto = {
      nome: nome,
      observacao: observacao,
      categoria: Number(categoria),
      status: status,
      ncm_id: ncm_id,
      vizualizar_vitrine: vizualizarVitrine,
    };

    api
      .patch(`/material-vitrine/${material_id}`, objeto)
      .then((response) => {
        // toast.success("Item Atualizado");
        // buscarMaterialVitrine();
        // buscarMaterialVitrineImagens();
        // buscarMaterialVitrineValores();
        atualizarMaterialVitrineValores();
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados");
      });
  };

  const atualizarMaterialVitrineValores = () => {
    for (let i = 0; i < materialVitrineValores.length; i++) {
      const objeto = {
        preco_unitario: getValues(
          `preco_unitario${materialVitrineValores[i].id}`
        ),
        quantidade_minima: getValues(
          `quantidade_minima${materialVitrineValores[i].id}`
        ),
      };

      api
        .patch(
          `/material-vitrine-valores/${materialVitrineValores[i].id}`,
          objeto
        )
        .then((response) => {
          buscarMaterialVitrine();
          buscarMaterialVitrineImagens();
          buscarMaterialVitrineValores();
        })
        .catch((error) => {
          toast.error("Erro ao buscar dados");
        });
    }
    toast.success("Faixa de Preços Atualizada");
  };

  useEffect(() => {
    buscarFornecedores();
    buscarNCM();
    if (fotosSelecionadas.length > 0) {
      adicionarImagem();
    }
  }, [fotosSelecionadas]);

  return (
    <FormAdministrarItemStyle>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate(`/vitrine/${material_id}`)}
            className="voltar"
            src={voltar}
          />
          <div>
            <h1>{materialVitrine.nome}</h1>
            <span className="underline">Gerenciar Item</span>
          </div>
        </div>
      </div>
      <FormStyle onSubmit={handleSubmit(atualizarMaterialVitrine)}>
        <div className="flex">
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox1"
              name="status"
              {...register("status")}
            />

            <label className="label-check" htmlFor="checkbox1">
              Ativo
            </label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox2"
              name="status"
              {...register("vizualizar_vitrine")}
            />

            <label className="label-check" htmlFor="checkbox2">
              Ativar Vizualização Na Vitrine
            </label>
          </div>
        </div>
        <div className="inputs">
          <Input
            label="Nome do Item"
            type="text"
            register={register("nome")}
            className="input-grid"
          />
          <div className="input-grid">
            <label>NCM</label>
            <select {...register("ncm_id")}>
              {ncms &&
                ncms.map((elem) => (
                  <option value={elem.id}>{elem.codigo}</option>
                ))}
            </select>
          </div>
          <div className="input-grid">
            <label>Categoria</label>
            <select {...register("categoria")}>
              {categorias &&
                categorias.map((elem) => (
                  <option value={elem.id}>{elem.nome}</option>
                ))}
            </select>
          </div>
        </div>
        <Input
          label="Descrição"
          type="text"
          register={register("observacao")}
          className="input-grid margin-top"
        />
        <div className="div-images">
          <h2>Foto</h2>
          <div className="flex">
            {materialVitrineImagens.map((elem, index) => (
              <div
                onClick={() => {
                  setImagemIndex(index);
                  setAbrirModalImagem(true);
                }}
              >
                <img src={elem.url} />
              </div>
            ))}
            <label htmlFor="file">
              <div className="adicionar-foto">
                <span>+</span>
                <span className="font">Adicionar foto</span>
              </div>
            </label>
            <input
              {...register("imagem")}
              type="file"
              id="file"
              hidden={true}
              accept="image/*"
              onChange={(e) => {
                setFotosSelecionadas([...e.target.files]);
              }}
            />
          </div>
        </div>
        <div className="div-cash">
          <h3 className="h3-red">Faixa do Preço</h3>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Quantidade mínima</th>
                  <th>Quantidade máxima</th>
                  <th>Preço unitário</th>
                  <th>Fornecedor</th>
                  <th>Prazo de entrega</th>
                  <th>Orcamento</th>
                  <th>Validade Orçamento</th>
                </tr>
              </thead>
              <tbody>
                {materialVitrineValores.length > 0 ? (
                  materialVitrineValores.map((elem, index) => {
                    return (
                      <FaixaDePreco
                        elem={elem}
                        fornecedor={fornecedor}
                        register={register}
                        reset={reset}
                        elemPosterior={materialVitrineValores[index + 1]}
                      />
                    );
                  })
                ) : (
                  <Loading />
                )}
              </tbody>
            </table>
          </TableContainer>
        </div>
        <button>Salvar</button>
      </FormStyle>
      {abrirModalImagem && (
        <ModalImagemAdministrarVitrine
          state={setAbrirModalImagem}
          materialVitrineImagens={materialVitrineImagens}
          index={imagemIndex}
          setImagemIndex={setImagemIndex}
          buscarMaterialVitrine={buscarMaterialVitrine}
          buscarMaterialVitrineImagens={buscarMaterialVitrineImagens}
          buscarMaterialVitrineValores={buscarMaterialVitrineValores}
        />
      )}
    </FormAdministrarItemStyle>
  );
};
