import styled from "styled-components";

export const ContainerStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 191, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .div-branca{
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: var(--base);
        padding: 40px 80px;
        width: 80%;
        max-width: 1000px;
        justify-content: flex-start;
        margin: 0 auto;
        text-align: start;
    }

    h2{
        display: flex;
        justify-content: flex-start;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    
    bold{
        font-weight: 600;
        margin: 0px 5px;
    }

    button{
        width: 100%;
        max-width: 200px;
        margin: 30px auto 10px;
        border-radius: 20px;
        background-color: rgba(100, 1, 1, 1);
        padding: 6px 0px;
        border: none;
        color: white;
    }

    span{
        color: rgb(136, 133, 127);
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 25px;
        cursor: pointer;
    }
`