import { useState } from "react"
import { ContainerStyle } from "./style"
import Loading from "../../Loading"
import api from "../../../services/api"
import { toast } from "react-toastify"
import { useAuth } from "../../../providers/Auth"

export const AvisoDoisFatores = ({ setModal }) => {
    const { user } = useAuth()

    function ativarDoisFatores() {
        api.patch("/usuarios/dois-fatores/ativar")
            .then((response) => {
                toast.success("Dois fatores Ativado!")
                setModal(false)
            })
            .catch(() => {
                toast.error("Erro ao enviar código de autenticação. Tente novamente.");
            });
    }

    const onSubmitFunction = () => {
        api
            .patch(`/usuarios/${user.id}`, { dois_fatores_chave: 0 })
            .then((response) => {
                toast.success("Usuário atualizado com sucesso!");
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <ContainerStyle>
            <div className="div-branca">
                {
                    !user
                        ?
                        <Loading />
                        :
                        <>
                            <h2>Aviso de Implentação do 2FA</h2>
                            <h3>
                                Para aumentar a segurança do seu acesso e do sistema, implementamos a
                                <bold>
                                    autenticação em dois fatores
                                </bold>
                                (2FA) para todos os usuários com o seu nível de permissões. Para ativá-la, clique em
                                <bold>
                                    "Ativar 2FA"
                                </bold>
                                e receba um código de autenticação no mesmo e-mail utilizado para o login.
                            </h3>
                            {/* <button onClick={() => {
                                ativarDoisFatores()
                            }
                            }>
                                Ativar 2FA
                            </button> */}
                            <span onClick={() => {
                                setModal(false)
                                onSubmitFunction()
                            }
                            }>
                                x
                            </span>
                        </>
                }
            </div>
        </ContainerStyle >
    )
}