
import RedefinirSenha from '../../components/RedefinirSenha';
import { NovaSenhaPageContainer } from './styles';

const RedefinirSenhaPage = () => {

    return (

        <NovaSenhaPageContainer>
            <RedefinirSenha />
        </NovaSenhaPageContainer>
    );
};

export default RedefinirSenhaPage;